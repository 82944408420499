import React from "react";
import { ReactSVG } from "react-svg";
import { Button, Grid } from "@mui/material";
import "./assets/homepage.scss";
import rightAero from "./assets/right-aero.svg";
import backgroundImageHome from "./assets/home-background.svg";
import backgroundImageHomeMobile from "./assets/farmer-mobile.svg";
import centralDiagram from "./assets/central-diagram.svg";
import centralDiagramMobile from "./assets/central-diagram-mobile.svg";
import bottomMobile from "./assets/mobile-bottom.svg";
import cycleDiagram from "./assets/cycle-diagram.svg";
import { useNavigate } from "react-router-dom";
import SliderReview from "../../Components/SliderReview";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import image1 from "./review-assets/Alpesh Patel.jpg";
import image2 from "./review-assets/Vinodbhai Patel.jpg";
import image3 from "./review-assets/Prashant.jpg";
import image4 from "./review-assets/Ramila ben.jpg";
import image5 from "./review-assets/Natvar Parmar.jpg";
import image6 from "./review-assets/Tofik masi.jpg";
import image7 from "./review-assets/Satish Patel.jpg";
import image8 from "./review-assets/shantibhai.jpg";
import image9 from "./review-assets/Joita Prajapati.jpg";
import image10 from "./review-assets/Yogesh Raval.jpg";
import image11 from "./review-assets/Roshan.jpg";
const HomePage = () => {
  let Navigate = useNavigate();
  const [language, setLanguage] = React.useState("English");
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 8,
    },
    desktop: {
      breakpoint: { max: 3000, min: 950 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 950, min: 600 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
    },
  };

  let reviewData = [
    {
      guj_comment:
        "બુલ કંપની સચોટ માહિતી આપનાર હોવાથી મિત્રો માટે આશીર્વાદ રૂપ છે. બુલ કંપનીની કાર્ય પદ્ધતિ પારદર્શક હોવાથી ખેડૂતોને છેતરવાનો ભય રહેતો નથી. કંપનીના કર્મચારીઓનું યોગ્ય વર્તન અને ભાવ નક્કી કર્યા બાદ ટાઈમ પર માલ ભરી જવાના કારણે ખૂબ જ વિશ્વાસપાત્ર બની છે. દરરોજના માર્કેટ ભાવ પ્રમાણે અને ગુણવત્તા પ્રમાણે ખરીદી કરતી હોવાથી બુલ કંપની ખેડૂતો માટે નંબર વન બની રહી છે.",
      eng_comment:
        "Bull company provides us with accurate information of trade and is like a boon for farmers. The practices of the company is transparent. Bull company has since become quite trustworthy due to the good behaviour of the staff and timely purchase from home. Bull Company buys according to the daily rate of market and offers price according to the quality. The Bull company has become the Number One company for farmers.",
      guj_address: "શંકરપુરા કંપા, બાયડ",
      eng_address: "Shankarpura Kampa, Bayad",
      guj_name: "અલ્પેશભાઈ પટેલ",
      eng_name: "Alpeshbhai Patel",
      image_url: image1,
    },
    {
      guj_comment:
        "મેં બુલ કંપનીમાં ૧૧૦ બોરી દિવેલાનું વેચાણ કરેલ છે. જેનું પેમેન્ટ ફક્ત ૧૨ કલાકમાં મને ચૂકવી દેવામાં આવ્યું અને તોલ પણ સાચો છે અને આ કંપનીની સર્વિસ ખૂબ જ સારી છે.",
      eng_comment:
        "I've sold 110 bags of castor through Bull Company. The payment was credited in my account within 12 hours and the weighing practices are also satisfactory. The services of the company are really good.",
      guj_address: "પટેલના મુવાડા, બાયડ",
      eng_address: "Patelna Muvada, Bayad",
      guj_name: "વિનોદભાઈ પટેલ",
      eng_name: "Vinodbhai Patel",
      image_url: image2,
    },
    {
      guj_comment:
        "તારીખ 10/08/2024 ના રોજ મેં દિવેલા વેચાણ અર્થે બુલ કંપનીમાં આપેલ, જેમાં મને તોલમાપની અને બજારભાવ ની બહુ સરસ કામગીરી જોવા મળી હતી તેમજ તેમના કર્મચારી બહુ સચોટ રીતે અને કાળજીપૂર્વક કામગીરી બજાવતા હતા. અન્ય ખેડૂત મિત્રોએ પણ ત્યાં વેચાણ કરવું યોગ્ય છે.",
      eng_comment:
        "On 10 August 2024 I contacted Bull Company to sell castor. Here I witnessed really good weighing practice and trade on live market prices. The staff was carefull and did accurate work. I also advise other farmers to sell through Bull Company.",
      guj_address: "દખનેશ્વર, બાયડ",
      eng_address: "Dakhneshwar, Bayad",
      guj_name: "પ્રશાંતકુમાર નટવરલાલ પટેલ",
      eng_name: "Prashantkumar Natvarlal Patel",
      image_url: image3,
    },
    {
      guj_comment:
        "મારા પતિના દુઃખદ અવસાન બાદ પાંચ બાળકોના ઉછેર, ખેતી અને ઢોર ઢાંખરનું બીડું મે ઉપાડ્યું હતું. એરંડા વેચવા માટે આજ રોજ મેં બુલ કંપનીનો સંપર્ક કર્યો. માર્કેટનો મને એટલો તો અનુભવ છે કે કોઈ માણસ ખેડૂત સામે જોતું નથી. પણ બુલ કંપનીના અશ્વિનભાઈએ મારી વાત જાણીને મને મહિના પછી પેમેન્ટ લેવાનો વિકલ્પ બતાવ્યો કે જેથી ₹૧૦ વધારે મળે અને ઇસેદુ મિલના પ્રગતિ પ્રોજેક્ટમાં અમારું નામ હતું જ. જ્યારે મોટા મોટા બજારો ૧૨૯૨-૯૮ નો ભાવ બતાવે છે મને બુલ કંપની દ્વારા ₹૧૩૨૫ નો ભાવ મળ્યો! તાત્કાલિક પૈસાની જરૂર ન હોવાથી મને મહિના પછીનું પેમેન્ટ યોગ્ય લાગ્યું, તથા બુલ કંપની ની પારદર્શિતા અને વિશ્વાસ તો ખરા જ. અપેક્ષાથી વધુ સહકાર બદલ હું બુલ કંપનીનો આભાર માનું છું.",
      eng_comment:
        "After the unfateful demise of my husband, I took up the resposibilty of raising five children, farmimg and our animals. I contacted Bull Company today to sell castor. I've enough experience of the market to know that nobody cares for the farmer. But Ashwinbhai from Bull Company listened and understood my situation, and suggested that I should opt for the 'payment after a month' so that I can get a 10 rupees raise on the rate. We were also registered under the Pragati project oh Ihsedu. Thus, when big markets were quoting 1292-98 rate, I was offered the rate of 1325. I chose the delayed payment as I had trust in the tranparency of the company. I thank Bull comapny for the unexpected supporte they have showed me.",
      guj_address: "કાબસો, ઇડર, સાબરકાંઠા",
      eng_address: "Kabso, Idar, Sabarkantha",
      guj_name: "રમીલાબેન ગજેન્દ્રભાઈ પરમાર",
      eng_name: "Ramilaben Gajendrabhai Parmar",
      image_url: image4,
    },
    {
      guj_comment:
        "બુલ કંપનીમાં મે દિવેલા વેચેલ અને તેના અનુભવમાં મને સારી એવી સર્વિસ મળેલ છે. ભાવ પણ માર્કેટ યાર્ડ કરતા સારા આપેલ છે. ત્યાંના કર્મચારીઓનો વ્યવહાર સારો છે. પેમેન્ટ ટાઇમસર મળી જાય છે અને ઘરે બેઠા માલ ભરાય છે. તોલ પણ ચોખ્ખો છે. કોઈ પણ પ્રકારની માહિતી સારી રીતે કર્મચારીઓ આપે છે. બુલ કંપની પાકનું વેચાણ કરવા માટે સારામાં સારી કંપની છે એવો મારો જાત અનુભવ છે.",
      eng_comment:
        "I've sold Castor to Bull Company and have experienced a good service. The rate was even higher than market yard rates. The staff is good in nature. The material was loaded from home and the payment was credited on time. The weighting is neat. All of my queries were addressed satisfactorily by the staff. By my own experience, I can say that the Bull Company is the best way to sell crop.",
      guj_address: "ભુખેલ, બાયડ",
      eng_address: "Bhukhel, Bayad",
      guj_name: "નટવરભાઈ ધુળાભાઈ પરમાર",
      eng_name: "Natvarbhai Dhulbhai Parmar",
      image_url: image5,
    },
    {
      guj_comment:
        "હું ખેતીનો વ્યવસાય કરું છું અને વધારે પ્રમાણમાં એરંડાનું વાવેતર કરું છું. છેલ્લા 3 વર્ષથી બુલ કંપનીના દેશોતર સેન્ટરમાં એરંડાનું વેચાણ કરું છું. સદર બુલ કંપનીની ખરીદ પદ્ધતિ, તોલમાપ, પેમેન્ટ ખૂબ જ સંતોષ કારક છે. તેમજ કંપનીના કર્મચારી મિત્રો પણ માલ લઈને આવનાર ખેડૂતો સાથે સારો વર્તાવ અને પૂરી ઈમાનદારીથી પોતાની ફરજ બજાવે છે. સાથે દરરોજના બજારભાવ બાબતમાં પણ ખેડૂતોને સજાગ કરે છે. જેથી બુલ કંપની ખેડૂતો માટે આશીર્વાદ રૂપ છે.",
      eng_comment:
        "I'm a farmer by occupation and sow castor. I've been selling at the Deshotar center of Bull company since 3 years. Company's processes of purchase, weighing and payment are all very good. Also the supervisors of Bull company ",
      guj_address: "કેશરપુરા, સાબરકાંઠા",
      eng_address: "Kesharpura, Sabarkantha",
      guj_name: "તોફિકભાઈ એ. મસી",
      eng_name: "Tofikbhai A. Masi",
      image_url: image6,
    },
    {
      guj_comment: `કર્મચારીઓનો વ્યવહાર સારો છે.
                    પેમેન્ટ કન્ડીશન સારી છે.
                    માપ તોલ કંપ્લેટ કરે છે. 
                    ઘરબેઠા ભરી જવામાં કોઈ તકલીફ નથી.`,
      eng_comment: "The field staff is very good. Payment condition is well explained at trade, and payment is always on time. Never had complaints about weighing. And the Buy from home facility is splendid.",
      guj_address: "કુબધરોલ કંપા, વડાલી, સાબરકાંઠા",
      eng_address: "Kubadharol Kapma, Vadali, Sabarakantha",
      guj_name: "સતિષભાઈ જેઠાભાઈ પટેલ",
      eng_name: "Satishbhai Jethabhai Patel",
      image_url: image7,
    },
    {
      guj_comment: `મેં બુલ કંપની સાથે 3 ગાડી, ૮૩૩ બોરી મગફળીનો વ્યાપાર કર્યો છે. બજારમાં સારામાં સારો ભાવ મને ઘરે બેઠા મળી ગયો. સોદો કર્યાના બીજા જ દિવસે માર્કેટમાં ₹૧૦૦ નો કડાકો બોલ્યો અને મને ચિંતા હતી કે ક્યાંક બીજા વ્યાપારીઓની જેમ બુલ સાથે પણ ભાવની રકજક થશે. પણ ના! આવતા 3 દિવસોમાં મારા ઘરેથી ગાડીઓ ભરાઈ અને મને એજ ભાવ મળ્યો! ગાડી ઘર છોડે એ પેહલા તો મારા ખાતામાં પેમેન્ટ આવી ગયું હતું.
                    મારો સમય બચ્યો અને સારામાં સારો ભાવ મળ્યો એનો મને સંતોષ છે. ખેડૂતોને આટલી સરસ સર્વિસ પૂરી પાડવા બદલ હું બુલ કંપનીનો આભારી છું!`,
      eng_comment: "I've sold 833 bags of Groundnut to Bull. I got the best price right at my home. A day after the rate was confirmed and I agreed on terms, the market crashed. I was worried if trade would be cancelled just like with traders. But No! In the next 3 days, the trucks were loaded from my home and I got the same rate as agreed. The payment was credited to my account before the trucks even left my home. A lot of my time was saved and got the best price. I'm thankful to Bull Company for providing such splendid service to farmers.",
      guj_address: "હરીપુરા કંપા, બાયડ, અરવલ્લી",
      eng_address: "Haripura Kampa, Bayad, Aravlli",
      guj_name: "શાંતિભાઈ મગનભાઈ પટેલ",
      eng_name: "Shantibhai Maganbhai Patel",
      image_url: image8,
    },
    {
      guj_comment:
        "બુલ કંપનીમાં અમોએ દિવેલા ભરાયા હતા. અમોને ભાવમાં ગંજ કરતા દસ રૂપિયાનો ભાવ વધારો મળ્યો. પેમેન્ટ કહ્યા પ્રમાણે ખાતામાં જમા થઈ ગયું ને તોલમાપમાં પણ બરાબર લાગ્યું ને સેન્ટર પર હાજર માણસનો સ્વભાવ પણ સારો છે. બુલ કંપની ઉત્તરોઉત્તર પ્રગતિ કરે અને ખેડૂતોને લાભ આપે એવી અમારી શુભેચ્છા. જય હિન્દ.",
      eng_comment: "I sold castor to Bull Company. I got Rs.10 more that the APMC rate. Payment was credited in my account as told. My well wishes to the company and I hope it profresses more and more. Jay Hind!",
      guj_address: "નાના કોઠાસણા, સતલાસણા",
      eng_address: "Nana Kothasana, Satlasana",
      guj_name: "જોઇતાભાઈ ડી. પ્રજાપતિ",
      eng_name: "Joitabhai D. Prajapati",
      image_url: image9,
    },
    {
      guj_comment:
        "હું છેલ્લા ત્રણ વર્ષથી બુલ કંપનીમાં માલ ભરાવું છું. બુલ કંપનીનું કામકાજ બહુ જ સારું છે. બુલ કંપનીના આવવાથી આસપાસના ખેડૂતોમાં સુધારો આવેલ છે. અમને રોજેરોજ ભાવ ફોનમાં આવવાથી અમારે માલ ક્યારે વેચવો તેની જાણકારી ફોનના માધ્યમથી સમયસર મળી રહે છે. બુલ કંપનીમાં પેમન્ટનું, તોલનું કામકાજ સારું છે. પેમન્ટ સમયસર ખાતામાં જમા થાય છે જે સંતોષકારક છે. સેન્ટરના સુપરવાઈઝર ખેડૂતને સંતોષકારક જવાબ આપે છે અને વાતચીતની રીત બહુ સારી છે.",
      eng_comment: "I've been selling castor to Bull since last 3 years. Work of the company is very good. The lives farmers around has improved after Bull company set up a center here. We receive daily prices on our phones and can decide when to sell our crop. The payment condition is satisfactory. We're explained everything in details by centre's supervisor.",
      guj_address: "દેશોતર, ઇડર",
      eng_address: "Deshotar, Idar",
      guj_name: "યોગેશકુમાર મગનલાલ રાવળ",
      eng_name: "Yogeshkumar Maganlal Raval",
      image_url: image10,
    },
    {
      guj_comment:
        "બુલ કંપની એક સંતોષકારક અને વિશ્વાસપાત્ર કંપની છે. તે ખેડૂતનો મિત્ર અને હિત સમાન છે. બુલ કંપનીમાં કાર્યકર્તાઓનો વ્યવહાર બઉ સરસ છે. માપતોલમાં પણ ચોકસાઈ રાખે છે. ઘરે બેઠા માલ ભરવામાં પણ સહયોગ આવે છે. માલ વેચાણ બાદ પેમેન્ટ ખૂબ જ ઝડપથી મળી જાય છે.",
      eng_comment: "Bull company is a trustworthy company. They are like a friend and well wisher for farmers. The staff is well trained of good nature. They take complete care of quality and weight. They are fully compliant for buying from home. The payment is fast after complition of trade.",
      guj_address: "ઝહિરપુરા, ઇડર",
      eng_address: "Zahirpura, Idar",
      guj_name: "રોશનઅલી ગુલામઅલી નાગલપરા",
      eng_name: "Roshanali Gulamali Nagalpara",
      image_url: image11,
    },
  ];
  return (
    <div className="home-page-container">
      <ReactSVG className="home-back-image" src={backgroundImageHome} />
      <ReactSVG
        className="home-back-image-mobile"
        src={backgroundImageHomeMobile}
      />
      <div className="home-page-container-abs">
        <div className="home-page-text-container">
          <span className="home-title">Revolutionising the</span>
          <span className="home-title-green">non-perishable</span>
          <span className="home-title">produce supply Chain</span>
        </div>
        <div className="desc-container">
          <span className="home-page-decription">
            Driven by the digital revolution, we source quality <br />
            assured non-perishable commodities directly <br />
            to the processing factories.
          </span>
        </div>
        <Button className="learn-more-btn" onClick={() => Navigate("/aboutus")}>
          Our Journey &nbsp;
          <img src={rightAero} alt="right-aero" />
        </Button>
      </div>
      <div className="about-us-container">
        <h3 className="title">About us</h3>
        <span className="desc">
          Bull Agritech is a commodity supply chain company which aims to
          connect farmers directly to the factories to conduct the trade by
          taking care of everything in between including logistics, quality
          assessment and payment protection.
        </span>
      </div>
      <div className="diagram-one">
        <img src={centralDiagram} className="centeral-diagram" alt="imgg" />
        {/* <ReactSVG className="centeral-diagram" src={centralDiagram} /> */}
        <img
          src={centralDiagramMobile}
          className="centeral-diagram-mobile"
          alt="imgg"
        />
        <div
          id="milestone-div-nav-scroll"
          style={{
            position: "absolute",
            bottom: "102px",
            fontSize: "0px",
          }}
        >
          555
        </div>
      </div>
      <div className="milestone-container">
        <h1 className="title">Milestones</h1>
        <Grid container className="info-container">
          <Grid item md={3} sm={6} xs={12} className="info">
            <span className="title">35,000+</span>
            <span className="description">Farmers connected digitally</span>
          </Grid>
          <Grid item md={3} sm={6} xs={12} className="info">
            <span className="title">80+ Cr</span>
            <span className="description">
              Rupees worth of produce sold with us
            </span>
          </Grid>
          <Grid item md={3} sm={6} xs={12} className="info">
            <span className="title">10,000+</span>
            <span className="description">
              Farmer trusted us with their produce
            </span>
          </Grid>
          <Grid item md={3} sm={6} xs={12} className="info">
            <span className="title">7</span>
            <span className="description">Warehouse collection centers</span>
          </Grid>
        </Grid>
      </div>
      <div className="cycle-diagram">
        <img
          src={cycleDiagram}
          className="cycle-diagram-img"
          alt="cycle-diagram-img"
        />
        <img
          src={bottomMobile}
          className="cycle-diagram-img-mobile"
          alt="cycle-diagram-img"
        />
        <div className="txt-one">
          <span className="first-line">Benefits for</span>
          <span className="second-line">Farmers</span>
        </div>
        <div className="txt-two">
          <span className="first-line">Benefits for</span>
          <span className="second-line">Agri-businesses</span>
        </div>
        <div className="static-container-text">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              flexDirection: "column",
            }}
          >
            <span className="static-string-title">
              What do people have to say about us?{" "}
              <select
                type="select"
                value={language}
                class="custom-select"
                onChange={(event) => setLanguage(event.target.value)}
              >
                <option value="English" class="custom-option">
                  English
                </option>
                <option value="ગુજરાતી" class="custom-option">
                  ગુજરાતી
                </option>
              </select>
            </span>
          </div>
          <Carousel
            responsive={responsive}
            infinite={true}
            autoPlay
            autoPlaySpeed={2500}
          >
            {reviewData.map((data) => (
              <div style={{ borderRadius: "20px", padding: "0px 10px" }}>
                <SliderReview data={data} language={language} />
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
