import React from "react";
import "./assets/slider-review.scss";

const SliderReview = (props) => {
  let {
    guj_comment,
    eng_comment,
    guj_address,
    eng_address,
    guj_name,
    eng_name,
    image_url,
  } = props.data
  let language = props.language
  const [hovered, setHovered] = React.useState(false);
  return (
    <div
      className="card-hover"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() =>
        setTimeout(() => {
          setHovered(false);
        }, 970)
      }
    >
      <div className="card-hover__content">
        {hovered && <p className="card-hover__text">{language === "English" ? eng_comment : guj_comment}</p>}
        {!hovered && (
          <div>
            <p className="card-hover__text_one">{language === "English" ? eng_name : guj_name}</p>
            <p className="card-hover__text_two">{language === "English" ? eng_address : guj_address}</p>
          </div>
        )}
      </div>
      <img src={image_url} alt="" />
    </div>
  );
};

export default SliderReview;
