import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "./assets/appheader.scss";
import AppLogo from "./assets/LogoNew.png";
import breadCumb from "./assets/bread-cumb.svg";
const AppHeader = () => {
  const Navigate = useNavigate();
  const handleDrawer = () => {
    document.getElementById("heder-app-container").className +=
      " header-app-css-animation";
  };
  const handleCloseDrawer = () => {
    let ele = document.getElementById("heder-app-container");
    ele.className = ele.className.split(" ")[0];
  };

  return (
    <div className="heder-app-container" id="heder-app-container">
      <div className="logo-app">
        <img
          src={AppLogo}
          className="app-logo-img"
          alt="app-logo"
          style={{height: "54px", cursor: "pointer"}}
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            Navigate("/");
            handleCloseDrawer();
          }}
        />
      </div>
      <div className="navigation-container">
        <NavLink
          to="/aboutus"
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            handleCloseDrawer();
          }}
          className={({ isActive }) =>
            isActive ? "active-route" : "default-route"
          }
        >
          Our Journey
        </NavLink>
        <a
          href="https://bull-agritech.medium.com/"
          target="_blank"
          rel="noreferrer"
          className="textdeco-none"
        >
          <div className="default-route">Blogs</div>
        </a>
        <div
          className="default-route"
          onClick={() => {
            Navigate("/");
            handleCloseDrawer();
            setTimeout(() => {
              let elem = document.getElementById("milestone-div-nav-scroll");
              elem?.scrollIntoView();
            }, 300);
          }}
        >
          Milestones
        </div>
        <div
          className="default-route"
          onClick={() => {
            Navigate("/");
            handleCloseDrawer();
            setTimeout(() => {
              let elem = document.getElementById("contact-us-scroll");
              elem?.scrollIntoView();
            }, 300);
          }}
        >
          Contact
        </div>
        <img
          src={breadCumb}
          className="bread-cumb"
          alt="bread-cumb"
          onClick={() => handleDrawer()}
        />
        <div className="close-modal-icon" onClick={handleCloseDrawer}>
          &#9587;
        </div>
      </div>
      <div className="navigation-container1">
        <NavLink
          to="/aboutus"
          onClick={handleCloseDrawer}
          className={({ isActive }) =>
            isActive ? "active-route1" : "default-route1"
          }
        >
          Our Journey
        </NavLink>
        <a
          href="https://bull-agritech.medium.com/"
          target="_blank"
          rel="noreferrer"
          className="textdeco-none"
        >
          <div className="default-route1">Blogs</div>
        </a>
        <div
          className="default-route1"
          onClick={() => {
            handleCloseDrawer();
            Navigate("/");
            setTimeout(() => {
              let elem = document.getElementById("milestone-div-nav-scroll");
              elem?.scrollIntoView();
            }, 300);
          }}
        >
          Milestones
        </div>
        <div
          className="default-route1"
          onClick={() => {
            handleCloseDrawer();
            Navigate("/");
            setTimeout(() => {
              let elem = document.getElementById("contact-us-scroll");
              elem?.scrollIntoView();
            }, 300);
          }}
        >
          Contact
        </div>
      </div>
    </div>
  );
};

export default AppHeader;
